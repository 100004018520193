
require('normalize.css/normalize.css');

require('./styles/index.scss');

import '@fortawesome/fontawesome-free/js/all';

document.addEventListener("DOMContentLoaded", () => {
    /*
        const pluginsTriggerElement = document.getElementById('plugins-trigger');
        const pluginsElement = document.getElementById('plugins');
    
        const pluginsVisibleClass = "splash-overview-plugins__list--visible";
    
        pluginsTriggerElement.onclick = () => {
            pluginsElement.classList.toggle(pluginsVisibleClass);
        }
    */
/*
   $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
            var a = e(this.hash);
            if ((a = a.length ? a : e("[name=" + this.hash.slice(1) + "]")).length) return e("html, body").animate({
                scrollTop: a.offset().top - 70
            }, 1e3, "easeInOutExpo"), !1
        }
    }), $(".js-scroll-trigger").click(function() {
        $(".navbar-collapse").collapse("hide")
    }), $("body").scrollspy({
        target: "#mainNav",
        offset: 100
    });*/
    var a = function() {
        100 < $("#mainNav").offset().top ? $("#mainNav").addClass("navbar-shrink") : $("#mainNav").removeClass("navbar-shrink")
    };
    a();
    $(window).scroll(a);

    var navMain = $(".navbar-collapse");
    navMain.on("click", "a:not([data-toggle])", null, function () {
        navMain.collapse('hide');
    });
});
